import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import {Box, Section, Text, Title} from "../components/Core";
import {graphql} from "gatsby"
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Template({data}) {

    const {markdownRemark} = data // data.markdownRemark holds your post data
    const {frontmatter, html} = markdownRemark
    const {t} = useTranslation('common');

    return (
        <>
            <PageWrapper footerDark>
                <Section>
                    <div className="pt-5"/>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="9" xl="8">
                                <Title variant="section" className="text-center">
                                    {frontmatter.title}
                                </Title>
                                <Box mt={["40px", null, "50px"]}>
                                    <div
                                        className="markdown-content"
                                        dangerouslySetInnerHTML={{__html: html}}
                                    />
                                </Box>
                                <hr/>
                                <Text variant="small" className={"text-right"}>
                                    {t('last-updated')} {frontmatter.date}
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};

export const query = graphql`
  query($language: String!, $slug: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "faq", "feature", "header", "hero", "newsletter", "pricing", "testimonial"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MM/DD/YYYY")
        slug
        title
      }
    }
  }
`
